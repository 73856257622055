import * as React from "react";
import { FormGroup, Checkbox, FormControlLabel, Typography, Slider, Accordion } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import "./SceneOptions.css";
import SpaceScene from "../../components/SpaceScene/SpaceScene";
import { Canvas } from "@react-three/fiber";
import { DEFAULT_EASY_VIEW_SCALE, MAX_SCALE } from "../../utils/constants";
import { strings } from "../../utils/strings";

export const SceneOptionsContext = React.createContext<SpaceSceneOptions>({
    easyViewMode: true,
    easyViewScale: DEFAULT_EASY_VIEW_SCALE,
    satelliteScale: 1,
    visible: {
        v15: true,
        v2mini: true,
        v2miniDTC: true,
        iss: true,
    },
    satelliteCount: {
        v15: 0,
        v2mini: 0,
        v2miniDTC: 0,
        iss: 0,
    },
    setSatelliteCount: () => {},
});

export interface SpaceSceneOptions {
    easyViewMode: boolean;
    easyViewScale: number;
    satelliteScale: number;
    visible: { [key: string]: boolean };
    satelliteCount: { [key: string]: number };
    setSatelliteCount: (satelliteCounts: { [key: string]: number }) => void;
}

const SceneOptions: React.FC = () => {
    const dtcOnly = window.location.pathname === '/dtc';
    const onSatelliteCountChange = (satelliteCounts: { [key: string]: number }) => {
        setOptions((prevOptions) => ({ ...prevOptions, satelliteCount: satelliteCounts }));
    }

    const [options, setOptions] = React.useState<SpaceSceneOptions>({
        easyViewMode: true,
        easyViewScale: dtcOnly ? MAX_SCALE : DEFAULT_EASY_VIEW_SCALE,
        satelliteScale: 1,
        visible: {
            v15: !dtcOnly, 
            v2mini: !dtcOnly,
            v2miniDTC: true,
            iss: !dtcOnly,
        },
        satelliteCount: {
            v15: 0,
            v2mini: 0,
            v2miniDTC: 0,
            iss: 0,
        },
        setSatelliteCount: onSatelliteCountChange
    });

    const onEasyViewModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOptions((prevOptions) => ({ ...prevOptions, easyViewMode: event.target.checked }));
    };

    const onEasyViewScaleChange = (event: Event, newValue: number | number[]) => {
        setOptions((prevOptions) => ({ ...prevOptions, easyViewScale: newValue as number }));
    };

    const onSatelliteScaleChange = (event: Event, newValue: number | number[]) => {
        setOptions((prevOptions) => ({ ...prevOptions, satelliteScale: newValue as number }));
    };

    const onFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOptions((prevOptions) => {
            const filter = { ...prevOptions.visible, [event.target.name]: event.target.checked };
            return { ...prevOptions, visible: filter };
        });
    };

    const v15String = strings.v15Name + (options.satelliteCount.v15 > 0 ? ` (${options.satelliteCount.v15})` : '');
    const v2miniString = strings.v2MiniName + (options.satelliteCount.v2mini > 0 ? ` (${options.satelliteCount.v2mini})` : '');
    const v2miniDTCString = strings.v2MiniDTCName + (options.satelliteCount.v2miniDTC > 0 ? ` (${options.satelliteCount.v2miniDTC})` : '');
    const issString = strings.issName + (options.satelliteCount.iss > 0 ? ` (${options.satelliteCount.iss})` : '');

    return (
        <div>
            <div id="canvas-container">
                <SceneOptionsContext.Provider value={options}>
                    <Canvas shadows>
                        <SpaceScene />
                    </Canvas>
                </SceneOptionsContext.Provider>
            </div>
            <div className="gui">
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox checked={options.easyViewMode} onChange={(event) => onEasyViewModeChange(event)} />}
                        label="Easy view mode"
                    />
                    <Typography id="input-slider" gutterBottom>
                        Satellite Size: {options.easyViewMode ? options.easyViewScale : options.satelliteScale}x
                    </Typography>
                    {options.easyViewMode ? (
                        <Slider
                            value={options.easyViewScale}
                            onChange={(event: Event, newValue: number | number[]) => onEasyViewScaleChange(event, newValue)}
                            min={1}
                            max={MAX_SCALE}
                            step={1}
                            valueLabelDisplay="auto"
                            valueLabelFormat={(value: number) => `${value}x`}
                        />
                    ) : (
                        <Slider
                            value={options.satelliteScale}
                            onChange={(event: Event, newValue: number | number[]) => onSatelliteScaleChange(event, newValue)}
                            min={1}
                            max={MAX_SCALE}
                            step={1}
                            valueLabelDisplay="auto"
                            valueLabelFormat={(value: number) => `${value}x`}
                        />
                    )}
                    <Accordion>
                        <AccordionSummary expandIcon={<ArrowDropDownIcon />} aria-controls="panel2-content" id="panel2-header">
                            <Typography>Filter</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox onChange={(event) => onFilterChange(event)} checked={options.visible.v15} name="v15" />}
                                label={v15String}
                                id="v15-checkbox"
                                className="filter-checkbox"
                            />
                            <FormControlLabel
                                control={<Checkbox onChange={(event) => onFilterChange(event)} checked={options.visible.v2mini} name="v2mini" />}
                                label={v2miniString}
                                id="v2mini-checkbox"
                                className="filter-checkbox"
                            />
                            <FormControlLabel
                                control={<Checkbox onChange={(event) => onFilterChange(event)} checked={options.visible.v2miniDTC} name="v2miniDTC" />}
                                label={v2miniDTCString}
                                id="v2miniDTC-checkbox"
                                className="filter-checkbox"
                            />
                            <FormControlLabel
                                control={<Checkbox onChange={(event) => onFilterChange(event)} checked={options.visible.iss} name="iss" />}
                                label={issString}
                                className="filter-checkbox"
                            />
                        </FormGroup>
                        </AccordionDetails>
                    </Accordion>
                </FormGroup>
            </div>
        </div>
    );
};

export default SceneOptions;
