import * as React from "react";
import { Routes, Route, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import "./App.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import CssBaseline from "@mui/material/CssBaseline";
import FPSCounter from "./components/FPSCounter/FPSCounter";
import AboutDialog from "./components/AboutDialog/AboutDialog";
import SceneOptions from "./components/SceneOptions/SceneOptions";
import { Typography } from "@mui/material";

const darkTheme = createTheme({
    palette: {
        mode: "dark",
    },
});
function App() {
    return (
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/dtc" element={<MainPage />} />
        </Routes>
      </ThemeProvider>
    );
  }

  function MainPage() {
    // Check the path and set the title and description accordingly
    const location = useLocation();
    let title = "Starlink Viewer";
    let description = "A realistic view of all Starlink satellites in orbit around the Earth in real time.";
    let pageTitle = title + ": " + description;
    let canonical = "https://www.starlinkviewer.com";
    
    if (location.pathname === "/dtc") {
        title = "Starlink Viewer: Direct to Cell";
        description = "Follow the buildout of the Starlink Direct to Cell constellation with realistic visualizations.";
        pageTitle = title;
        canonical = "https://www.starlinkviewer.com/dtc";
    }
    return (
      <>
        <Helmet>
          <title>{pageTitle}</title>
          <link rel="canonical" href={canonical}/>
          <meta name="description" content={description}/>
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={description} />
        </Helmet>
        <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <div id="loading-popup">
                <Typography variant="h3" gutterBottom>
                    Loading...
                    <br />
                    <CircularProgress size="4rem" />
                </Typography>
            </div>
            <SceneOptions />
            {process.env.NODE_ENV === "development" && <FPSCounter />}
            <div id="gso-tooltip" className="info-tooltip">
                <h2>Geostationary Orbit Altitude</h2>
            </div>
            <div id="copyright-block">&copy; {new Date().getFullYear()} Mouser Labs</div>
            <AboutDialog />
        </ThemeProvider>
      </>
    );
  }

export default App;
