import * as React from "react";
import * as THREE from "three";
import { extend, useThree, useFrame } from "@react-three/fiber";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { EARTH_RADIUS, GSO_RADIUS } from "../../utils/constants";

const MIN_ALTITUDE = 360;
const IS_TOUCH = window.matchMedia("(pointer: coarse)").matches
const MAX_ALTITUDE = IS_TOUCH ? 12000 : 6000;
const MIN_ROTATE_SPEED = IS_TOUCH ? 0.005 : 0.005;
const MAX_ROTATE_SPEED = IS_TOUCH ? 0.5 : 0.2;
const MIN_ZOOM_SPEED = 0.01;
const MAX_ZOOM_SPEED = 0.5;


// Extend OrbitControls to include custom functionality
class CustomOrbitControls extends OrbitControls {
    constructor(camera: THREE.Camera, domElement: HTMLElement) {
        super(camera, domElement);
        this.enablePan = false; // Disable default panning
        this.minDistance = EARTH_RADIUS + MIN_ALTITUDE;
        this.maxDistance = GSO_RADIUS;
        this.zoomSpeed = 0.5;
        this.mouseButtons = {
            LEFT: THREE.MOUSE.ROTATE,
            MIDDLE: THREE.MOUSE.DOLLY,
            RIGHT: THREE.MOUSE.ROTATE, // Custom behavior on right click TBD
        };
    }
}

extend({ CustomOrbitControls });

const OrbitalControls: React.FC = () => {
    const { camera, gl, invalidate } = useThree();
    const controlsRef = React.useRef<CustomOrbitControls>();

    React.useEffect(() => {
        const controls = new CustomOrbitControls(camera, gl.domElement);
        controlsRef.current = controls;
        invalidate();

        return () => {
            controls.dispose();
        };
    }, [camera, gl, invalidate]);

    useFrame(() => {
        if (controlsRef.current) {
            const altitude = Math.min(1.0, (camera.position.length() - EARTH_RADIUS - MIN_ALTITUDE) / MAX_ALTITUDE);
            const rotateSpeed = MIN_ROTATE_SPEED + altitude * MAX_ROTATE_SPEED;
            const zoomSpeed = MIN_ZOOM_SPEED + altitude * MAX_ZOOM_SPEED;
            controlsRef.current.rotateSpeed = rotateSpeed;
            controlsRef.current.zoomSpeed = zoomSpeed;
            controlsRef.current.update(); // Ensure controls are updated if manual update is necessary
        }

        const element = document.getElementById("gso-tooltip");
        if (element) {
            if (camera.position.length() >= GSO_RADIUS - 100) {
                element.style.display = "block";
            } else {
                element.style.display = "none";
            }
        }
    });

    return null; // As controls are manually attached, we do not return any JSX.
};

export default OrbitalControls;
