// SpaceScene.js
import * as React from "react";
import { useEffect } from "react";
import * as THREE from "three";
import Sun from "../Sun/Sun";
import OrbitalControls from "../OrbitalControls/OrbitalControls";
import Earth from "../Earth/Earth";
import { useThree, useLoader } from "@react-three/fiber";
import { GLTF, GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { fetchTLEs, TleList } from "../../utils/satelliteData";
import Starmap from "../../assets/textures/starmap_2020_4k.avif";
import StarmapIos from "../../assets/textures/starmap_2020_ios.avif";
import v15Model from "../../assets/models/v15.glb";
import v2miniModel from "../../assets/models/starlinkv2mini.glb";
import dtcModel from "../../assets/models/v2minidtc.glb";
import issModel from "../../assets/models/ISS.glb";
import SatelliteGroup from "../Satellite/SatelliteGroup";
import { SceneOptionsContext } from "../SceneOptions/SceneOptions";
import "./SpaceScene.css";
import { IS_IOS } from "../../utils/constants";

// Check if user is landscape mode
const isLandscape = () => window.innerWidth > window.innerHeight;
const defaultCameraZ = isLandscape() ? 15000 : 30000;

const loader = new THREE.TextureLoader();
const starMapTexture = IS_IOS ? StarmapIos : Starmap;

const SpaceScene: React.FC = () => {
    const [tleList, setTleList] = React.useState<TleList>({
        v15: [],
        v2mini: [],
        v2miniDTC: [],
        iss: [],
    });
    const sceneOptions = React.useContext(SceneOptionsContext);

    const { scene, camera } = useThree();
    const perspectiveCamera = React.useMemo(() => camera as THREE.PerspectiveCamera, [camera]);
    React.useEffect(() => {
        perspectiveCamera.position.set(0, 0, defaultCameraZ);
        perspectiveCamera.near = 10;
        perspectiveCamera.far = 100000;
        perspectiveCamera.fov = 50;
        perspectiveCamera.updateProjectionMatrix();
    }, [perspectiveCamera]);

    const v15Gltf = useLoader(GLTFLoader, v15Model) as GLTF;
    const v2miniGltf = useLoader(GLTFLoader, v2miniModel) as GLTF;
    const dtcGltf = useLoader(GLTFLoader, dtcModel) as GLTF;
    const issGltf = useLoader(GLTFLoader, issModel) as GLTF;

    // Remove loading after models and TLEs are in. Close enough...
    React.useEffect(() => {
        if (v15Gltf && v2miniGltf && dtcGltf && issGltf && tleList.v2mini.length > 0) {
            document.getElementById("loading-popup")?.remove();
        }
    }, [v15Gltf, v2miniGltf, dtcGltf, issGltf, tleList]);

    // Fetch TLE data and update the state
    const updateTleList = async () => {
        let response = await fetchTLEs();
        setTleList(response);
        sceneOptions.setSatelliteCount({
            v15: response.v15.length,
            v2mini: response.v2mini.length,
            v2miniDTC: response.v2miniDTC.length,
            iss: response.iss.length,
        });
    };
    useEffect(() => {
        updateTleList();
        const interval = setInterval(() => {
            updateTleList();
        }, 4 * 60 * 60 * 1000); // Reload TLE data every 1 hours

        // Clean up on component unmount
        return () => {
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        scene.background = loader.load(starMapTexture);
        scene.background.mapping = THREE.EquirectangularReflectionMapping;
    }, [scene]);

    return (
        <>
            <Sun />
            <Earth />
            <OrbitalControls />
            {
                /* If models are loaded render satellites */
                v2miniGltf && issGltf && dtcGltf && (
                    <>
                        <SatelliteGroup divId="iss" tles={tleList.iss} gltf={issGltf} />
                        <SatelliteGroup divId="v15" tles={tleList.v15} gltf={v15Gltf} />
                        <SatelliteGroup divId="v2mini" tles={tleList.v2mini} gltf={v2miniGltf} />
                        <SatelliteGroup divId="v2miniDTC" tles={tleList.v2miniDTC} gltf={dtcGltf} />
                    </>
                )
            }
        </>
    );
};
export default SpaceScene;
