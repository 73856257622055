const urls = [
  "https://www.starlinkviewer.com/data/starlink-tle.txt",
  "https://www.starlinkviewer.com/data/iss-tle.txt",
];

export type TleList = {
  v15: string[][];
  v2mini: string[][];
  v2miniDTC: string[][];
  iss: string[][];
};

export async function fetchTLEs(): Promise<TleList> {
  const allTLEs: TleList = {
    v15: [],
    v2mini: [],
    v2miniDTC: [],
    iss: [],
  };
  try {
    for (const url of urls) {
      const response: Response = await fetch(url, { mode: "cors" });
      const data: string = await response.text();

      // Split the data into lines
      const lines: string[] = data.trim().split(/\r?\n/);

      // Group every three lines into an array
      const tles: Array<Array<string>> = [];
      for (let i: number = 0; i < lines.length; i += 3) {
        tles.push([lines[i], lines[i + 1], lines[i + 2]]);
        if (lines[i].includes("[DTC]")) {
          allTLEs.v2miniDTC.push([lines[i], lines[i + 1], lines[i + 2]]);
        } else if (parseInt(lines[i].split("-")[1]) >= 30000) {
          allTLEs.v2mini.push([lines[i], lines[i + 1], lines[i + 2]]);
        } else if (lines[i].includes("ISS")) {
          allTLEs.iss.push([lines[i], lines[i + 1], lines[i + 2]]);
        } else {
          allTLEs.v15.push([lines[i], lines[i + 1], lines[i + 2]]);
        }
      }
    }
    return allTLEs;
  } catch (error) {
    console.error("Failed to fetch or parse TLEs:", error);
    return allTLEs;
  }
}


