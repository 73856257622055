import * as React from "react";
import { useRef, useEffect } from "react";
import { DirectionalLight } from 'three';
import { calculateSunPosition } from "../../utils/utils";
import { SUN_DISTANCE } from "../../utils/constants";

const Sun: React.FC = () => {
    const sunRef = useRef<DirectionalLight>(null!);


    useEffect(() => {
        // Get renderer
        const sunPosition = calculateSunPosition();
        sunRef.current.position.set(sunPosition.x, sunPosition.y, sunPosition.z);
        sunRef.current.intensity = 2;
        sunRef.current.castShadow = true;
        sunRef.current.shadow.mapSize.width = 1024;
        sunRef.current.shadow.mapSize.height = 1024;
        sunRef.current.shadow.camera.near = SUN_DISTANCE * -8000; // Closer near plane
        sunRef.current.shadow.camera.far = SUN_DISTANCE + 8000; // Far plane should match the camera's far plane
        sunRef.current.shadow.camera.left = -8000; // Increase the size of the shadow frustum
        sunRef.current.shadow.camera.right = 8000;
        sunRef.current.shadow.camera.top = 8000;
        sunRef.current.shadow.camera.bottom = -8000;
        sunRef.current.lookAt(0, 0, 0);
    }, []);

    useEffect(() => {
        // Update sun position every 24 hours
        const interval = setInterval(() => {
            const sunPosition = calculateSunPosition();
            sunRef.current.position.set(sunPosition.x, sunPosition.y, sunPosition.z);
        }, 1000 * 60 * 60 * 24);
        return () => clearInterval(interval);
    });

    return (
        <directionalLight
            ref={sunRef}
            position={[0, 10, 0]} // Set the position of the sun
            intensity={1} // Set the intensity of the light
            color="white" // Set the color of the light
            castShadow // Enable shadow casting
        />
    );
};

export default Sun;