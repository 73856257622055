import * as React from "react";
import * as THREE from "three";
import { useFrame, useLoader } from "@react-three/fiber";
import { TextureLoader } from "three/src/loaders/TextureLoader";
import { getCurrentDate } from "../../utils/utils";
import { useThree } from "@react-three/fiber";

import cloudMap from "../../assets/textures/clouds-4k.avif";
import cloudMapIOS from "../../assets/textures/clouds-ios.avif";
import { EARTH_RADIUS, IS_IOS } from "../../utils/constants";

const cloudFilePath = IS_IOS ? cloudMapIOS : cloudMap;

const Clouds: React.FC = () => {
  const meshRef = React.useRef<THREE.Mesh>(null!);
  const [cloudTexture] = useLoader(TextureLoader, [cloudFilePath]);
  const { camera } = useThree();

  useFrame(() => {
    let now = getCurrentDate();
    let secondsIntoTheDay =
      now.getUTCHours() * 3600 +
      now.getUTCMinutes() * 60 +
      now.getUTCSeconds() +
      now.getUTCMilliseconds() / 1000;
    let rotationOffset = 0.98 * Math.PI; // Due to 0 not aligning with UTC.
    let rotationAmount =
      rotationOffset + (secondsIntoTheDay / (24 * 3600)) * Math.PI * 2;
    meshRef.current.rotation.y = rotationAmount;

    // Radiuses are too similar so we need to scale the clouds a bit when far away.
    if (camera && camera.position.distanceTo(new THREE.Vector3()) > 20000) {
      const scale = 1.002;
      meshRef.current.scale.set(scale, scale, scale);
    } else {
      meshRef.current.scale.set(1, 1, 1);
    }
  });

  return (
    <mesh ref={meshRef}>
      <sphereGeometry args={[EARTH_RADIUS + 10, 256, 256]} />
      <meshStandardMaterial
        map={cloudTexture}
        bumpMap={cloudTexture}
        bumpScale={1}
        alphaMap={cloudTexture}
        transparent={true}
      />
    </mesh>
  );
};

export default Clouds;
