import * as React from "react";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import { AppBar, Tabs, Tab, Link } from "@mui/material";
import Modal from "@mui/material/Modal";
import "./AboutDialog.css";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`full-width-tabpanel-${index}`} aria-labelledby={`full-width-tab-${index}`} {...other}>
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

const style = {
    position: "absolute",
    top: "10%",
    left: "50%",
    transform: "translate(-50%, 0%)",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
};

export default function AboutDialog() {
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState(0);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <Fab id="about-fab" variant="extended" onClick={handleOpen} color="primary">
                About
            </Fab>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                keepMounted
                className="overflow-modal"
            >
                <Box sx={style} id="testing">
                    <AppBar position="static">
                        <Tabs value={value} onChange={handleChange} variant="fullWidth" aria-label="full width tabs example">
                            <Tab label="Instructions" />
                            <Tab label="About" />
                            <Tab label="FAQ" />
                        </Tabs>
                    </AppBar>
                    <TabPanel className="panel-scrollable" value={value} index={0}>
                        <p>
                            There are two viewing modes. By default Easy View Mode is enabled. This causes the satellites to be rendered fully illuminated at
                            all times. They also are a constant size. With Easy View Mode disabled, the satellites are rendered with the correct lighting based
                            on their position relative to the sun. This means that they will be dark when they are in the earth's shadow. The size of the
                            satellites also defaults to 1x which is not able to be seen because they are so small. You can adjust the scale of the satellites in
                            either mode using the slider in the top left.
                        </p>
                        <p>
                            You can use the left mouse button or touch and drag to rotate the camera. Use the mouse wheel or pinch to zoom in and out. You can
                            also use the right mouse button or two finger touch to pan the camera.
                        </p>
                        <p>
                            In the normal view mode, satellites near the mouse are linearly scaled by distance from the mouse. This is to help see the
                            satellites better. Otherwise it would be nearly impossible to locate them. In both view modes, you can hover over a satellite to see
                            its name and altitude. This feature is disabled on mobile.
                        </p>
                    </TabPanel>
                    <TabPanel className="panel-scrollable" value={value} index={1}>
                        <p>
                            I built this website to help give a realistic view of the Starlink satellite constellation. Many websites tend to make space look
                            very crowded by drawing huge objects in place of satellites. This website attempts to use real scale for everything to give a more
                            accurate representation of the situation in space. Now, the satellites by default are rendered larger so that they can be seen, but
                            the options allow you to adjust them down to 1x scale.
                        </p>
                        <p>
                            The earth, clouds, atmosphere, and satellite altitudes are all to scale. The angle of the sun and rotation of the earth are also
                            accurate. I've also used different earth textures depending on the month to accurately show the snow and ice coverage on the earth.
                            Even the stars in the background are accurate to the current date and time, though I need to verify that as the seasons change.
                        </p>
                        <p>
                            Follow me on X: <Link target="_blank" href="https://twitter.com/mouser58907">Mouser58907</Link>
                        </p>
                    </TabPanel>
                    <TabPanel className="panel-scrollable" value={value} index={2}>
                        <h2>Are you going to add all Space Objects?</h2>
                        <p>I don't think so, but I may add some more in the future. What would you like to see?</p>
                        <h2>What features are going to be added?</h2>
                        <p>
                            First off, better camera controls. I think you should be able to click to focus on a satellite. Then, I would like to add the sun
                            and moon. Finally, I would like to add real time accurate clouds but I don't think there is a data source for that.
                        </p>
                        <h2>Are there any Easter Eggs?</h2>
                        <p>Just one, but it's extremely obvious now that I added filters. If you find it, let me know on X!</p>
                        <h2>What is Starlink?</h2>
                        <p>
                            Starlink is a satellite internet constellation being constructed by SpaceX providing satellite Internet access. The constellation
                            will consist of thousands of mass-produced small satellites in low Earth orbit (LEO), working in combination with ground
                            transceivers. SpaceX intends to provide satellite internet connectivity to underserved areas of the planet, as well as provide
                            competitively priced service to urban areas. <Link target="_blank" href="https://www.starlink.com/">Learn more here!</Link>
                        </p>
                        <h2>Is Starlink internet good?</h2>
                        <p>
                            I've had great results with it. I've used it from Texas to Iowa in parking lots, fields, beaches, and farmland and never had any
                            issues. I've even been able to upload live streamed 4K. If you're in an area without good internet, I couldn't recommend it enough.
                        </p>
                        <h2>This website sucks.</h2>
                        <p>
                            First off, that isn't a question. Second, it works on phones but is not intended for mobile. If you think it sucks and aren't on
                            mobile, fair. If you have some constructive feedback, I'd love to hear it. Let me know on X at <Link target="_blank" href="https://twitter.com/mouser58907">@Mouser58907</Link>
                        </p>
                    </TabPanel>
                </Box>
            </Modal>
        </div>
    );
}
