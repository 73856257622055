import * as THREE from "three";
import { SUN_DISTANCE } from "./constants";
const satellite = require("satellite.js");

export function calculateSunPosition(): THREE.Vector3 {
  const now: Date = getCurrentDate();
  const dayOfYear: number =
    (now.getTime() - new Date(now.getFullYear(), 0, 0).getTime()) / 1000 / 60 / 60 / 24;

  const daysInYear: number = 365.24;
  const springSolstice: number = 79;

  const angle: number = (2 * Math.PI * (dayOfYear - springSolstice)) / daysInYear;
  const tilt: number = 23.5 * Math.sin(angle); // Earth's tilt is 23.5 degrees
  const x = SUN_DISTANCE * Math.cos(THREE.MathUtils.degToRad(tilt));
  const y = SUN_DISTANCE * Math.sin(THREE.MathUtils.degToRad(tilt));
  return new THREE.Vector3(x, y, 0);
}

export function getUpdatedSatellitePosition(satrec: any): [THREE.Vector3, THREE.Vector3]{
  var positionAndVelocity = satellite.propagate(satrec, getCurrentDate());
  var positionECI = positionAndVelocity.position;
  
  // Convert ECI coordinates to Three.js vector
  const positionVector = new THREE.Vector3(
    positionECI.y,
    positionECI.z,
    positionECI.x
  );

  const velocityVector = new THREE.Vector3(
    positionAndVelocity.velocity.y,
    positionAndVelocity.velocity.z,
    positionAndVelocity.velocity.x
  );

  // Rotate the vector to match the Earth's orientation
  const daysSinceAligned = (getCurrentDate().getTime() - new Date("2024-04-24T00:00:00Z").getTime()) / (1000 * 60 * 60 * 24);
  const rotationRadians = daysSinceAligned * 0.9856123035484 * Math.PI / 180; // https://space.stackexchange.com/questions/38807/transform-eci-to-ecef
  const initialOffset = 1.17 * (0.5 * Math.PI) / 2; // Adding 1.17 to align here. If it's wrong in the future, remove this and affect above.
  const offset = initialOffset - rotationRadians;
  velocityVector.applyAxisAngle(
    new THREE.Vector3(0, 1, 0),
    offset
  );
  positionVector.applyAxisAngle(
    new THREE.Vector3(0, 1, 0),
    offset
  );

  // Update the satellite's position
  return [positionVector, velocityVector];
};


export function getCurrentDate(): Date {
  return new Date();
}

// const currentDate = new Date();
// export function getCurrentDate(): Date {
//   // Add 10 minutes
//   currentDate.setMinutes(currentDate.getMinutes() + 1);
//   return currentDate;
// }
