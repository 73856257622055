import * as React from "react";
import { useEffect } from "react";
import * as THREE from "three";
import { useFrame, useLoader } from "@react-three/fiber";
import { TextureLoader } from "three/src/loaders/TextureLoader";
import { getCurrentDate } from "../../utils/utils";
import Clouds from "../Clouds/Clouds";
import Atmosphere from "../Atmosphere/Atmosphere";

import earthBump from "../../assets/textures/earth/earth-bump.avif";
import earthLight from "../../assets/textures/earth/earth-lights.avif";
import earthOceans from "../../assets/textures/earth/earth-oceans.avif";
import clouds from "../../assets/textures/clouds-4k.avif";

import earthBumpIOS from "../../assets/textures/earth/earth-bump-ios.avif";
import earthLightIOS from "../../assets/textures/earth/earth-lights-ios.avif";
import earthOceansIOS from "../../assets/textures/earth/earth-oceans-ios.avif";
import cloudsIOS from "../../assets/textures/clouds-ios.avif";

import janTexture from "../../assets/textures/earth/1-4k.avif";
import febTexture from "../../assets/textures/earth/2-4k.avif";
import marTexture from "../../assets/textures/earth/3-4k.avif";
import aprTexture from "../../assets/textures/earth/4-4k.avif";
import mayTexture from "../../assets/textures/earth/5-4k.avif";
import junTexture from "../../assets/textures/earth/6-4k.avif";
import julTexture from "../../assets/textures/earth/7-4k.avif";
import augTexture from "../../assets/textures/earth/8-4k.avif";
import sepTexture from "../../assets/textures/earth/9-4k.avif";
import octTexture from "../../assets/textures/earth/10-4k.avif";
import novTexture from "../../assets/textures/earth/11-4k.avif";
import decTexture from "../../assets/textures/earth/12-4k.avif";

import janIOSTexture from "../../assets/textures/earth/1-ios.avif";
import febIOSTexture from "../../assets/textures/earth/2-ios.avif";
import marIOSTexture from "../../assets/textures/earth/3-ios.avif";
import aprIOSTexture from "../../assets/textures/earth/4-ios.avif";
import mayIOSTexture from "../../assets/textures/earth/5-ios.avif";
import junIOSTexture from "../../assets/textures/earth/6-ios.avif";
import julIOSTexture from "../../assets/textures/earth/7-ios.avif";
import augIOSTexture from "../../assets/textures/earth/8-ios.avif";
import sepIOSTexture from "../../assets/textures/earth/9-ios.avif";
import octIOSTexture from "../../assets/textures/earth/10-ios.avif";
import novIOSTexture from "../../assets/textures/earth/11-ios.avif";
import decIOSTexture from "../../assets/textures/earth/12-ios.avif";
import { IS_IOS } from "../../utils/constants";

const monthTextures = [
  janTexture,
  febTexture,
  marTexture,
  aprTexture,
  mayTexture,
  junTexture,
  julTexture,
  augTexture,
  sepTexture,
  octTexture,
  novTexture,
  decTexture,
];
const iosMonthTextures = [
  janIOSTexture,
  febIOSTexture,
  marIOSTexture,
  aprIOSTexture,
  mayIOSTexture,
  junIOSTexture,
  julIOSTexture,
  augIOSTexture,
  sepIOSTexture,
  octIOSTexture,
  novIOSTexture,
  decIOSTexture,
];
const curMonth = getCurrentDate().getMonth(); // getMonth() returns 0 for January, 11 for December
const earthImg = IS_IOS ? iosMonthTextures[curMonth] : monthTextures[curMonth];
const earthBumpImg = IS_IOS ? earthBumpIOS : earthBump;
const earthLightImg = IS_IOS ? earthLightIOS : earthLight;
const earthOceansImg = IS_IOS ? earthOceansIOS : earthOceans;
const cloudTexture = IS_IOS ? cloudsIOS : clouds;

const Earth: React.FC = () => {
  const meshRef = React.useRef<THREE.Mesh>(null!);
  const materialRef = React.useRef<THREE.MeshStandardMaterial>(null!);
  const [earthTexture, earthBump, earthLight, earthOceans, cloudsMap] =
    useLoader(TextureLoader, [
      earthImg,
      earthBumpImg,
      earthLightImg,
      earthOceansImg,
      cloudTexture,
    ]);
  useEffect(() => {
    materialRef.current.onBeforeCompile = function (shader) {
      shader.fragmentShader = shader.fragmentShader.replace(
        "#include <roughnessmap_fragment>",
        `
            float roughnessFactor = roughness;
    
            #ifdef USE_ROUGHNESSMAP
    
              vec4 texelRoughness = texture2D( roughnessMap, vRoughnessMapUv );
              // reversing the black and white values because we provide the ocean map
              // texelRoughness = vec4(1.0) - texelRoughness;
    
              // reads channel G, compatible with a combined OcclusionRoughnessMetallic (RGB) texture
              roughnessFactor *= clamp(texelRoughness.g, 0.6, 1.0);
    
            #endif
          `
      );
      shader.uniforms.tClouds = { value: cloudsMap };
      shader.uniforms.tClouds.value.wrapS = THREE.RepeatWrapping;
      shader.uniforms.uv_xOffset = { value: 0 };
      shader.fragmentShader = shader.fragmentShader.replace(
        "#include <common>",
        `
          #include <common>
          uniform sampler2D tClouds;
          uniform float uv_xOffset;
        `
      );

      shader.fragmentShader = shader.fragmentShader.replace(
        "#include <emissivemap_fragment>",
        `
          #include <emissivemap_fragment>
          #ifdef USE_EMISSIVEMAP
  
            emissiveColor = texture2D( emissiveMap, vEmissiveMapUv );
  
            // Methodology of showing night lights only:
            //
            // going through the shader calculations in the meshphysical shader chunks (mostly on the vertex side),
            // we can confirm that geometryNormal is the normalized normal in view space,
            // for the night side of the earth, the dot product between geometryNormal and the directional light would be negative
            // since the direction vector actually points from target to position of the DirectionalLight,
            // for lit side of the earth, the reverse happens thus emissiveColor would be multiplied with 0.
            // The smoothstep is to smoothen the change between night and day
            
            emissiveColor *= 1.0 - smoothstep(-0.02, 0.0, dot(vNormal, directionalLights[0].direction));
            
            totalEmissiveRadiance *= emissiveColor.rgb;
  
          #endif
  
        
          float cloudsMapValue = texture2D(tClouds, vec2(vMapUv.x, vMapUv.y)).r;
          diffuseColor.rgb *= max(1.0 - cloudsMapValue*1.5, 0.1 );
  
          float intensity = 1.4 - dot( vNormal, vec3( 0.0, 0.0, 1.0 ) );
          vec3 atmosphere = vec3( 0.3, 0.6, 1.0 ) * pow(intensity, 5.0);
         
          diffuseColor.rgb += atmosphere;
        `
      );
    };
  }, [materialRef, cloudsMap]);

  useFrame(() => {
    let now = getCurrentDate();
    let secondsIntoTheDay =
      now.getUTCHours() * 3600 +
      now.getUTCMinutes() * 60 +
      now.getUTCSeconds() +
      now.getUTCMilliseconds() / 1000;
    let rotationOffset = 0.98 * Math.PI; // Due to 0 not aligning with UTC.
    let rotationAmount =
      rotationOffset + (secondsIntoTheDay / (24 * 3600)) * Math.PI * 2;
    meshRef.current.rotation.y = rotationAmount;
  });

  return (
    <>
      <mesh castShadow ref={meshRef}>
        <sphereGeometry args={[6378, 256, 256]} />
        <meshStandardMaterial
          map={earthTexture}
          bumpMap={earthBump}
          bumpScale={0.1}
          roughnessMap={earthOceans}
          metalnessMap={earthOceans}
          metalness={0.01}
          emissiveMap={earthLight}
          emissive={0xffff88}
          emissiveIntensity={0.2}
          ref={materialRef}
        />
      </mesh>
      <Clouds />
      <Atmosphere />
    </>
  );
};

export default Earth;
