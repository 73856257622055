import * as React from "react";
import * as THREE from "three";
import { EARTH_RADIUS } from "../../utils/constants";

const vertexShader = `
    varying vec3 vNormal;
    varying vec3 eyeVector;

    void main() {
        vec4 mvPos = modelViewMatrix * vec4(position, 1.0);
        vNormal = normalize(normalMatrix * normal);
        eyeVector = normalize(mvPos.xyz);
        gl_Position = projectionMatrix * mvPos;
    }
`;

const fragmentShader = `
    varying vec3 vNormal;
    varying vec3 eyeVector;
    uniform float atmOpacity;
    uniform float atmPowFactor;
    uniform float atmMultiplier;

    void main() {
        float dotP = dot( vNormal, eyeVector );
        float factor = pow(dotP, atmPowFactor) * atmMultiplier;
        vec3 atmColor = vec3(0.35 + dotP/4.5, 0.35 + dotP/4.5, 1.0);
        gl_FragColor = vec4(atmColor, dotP * atmOpacity) ;
    }
`;


const Atmosphere: React.FC = () => {

  return (
    <>
      <mesh receiveShadow={false}>
        <sphereGeometry args={[EARTH_RADIUS + 100, 256, 256]} />
        <shaderMaterial
          vertexShader={vertexShader}
          fragmentShader={fragmentShader}
          uniforms={{
            atmOpacity: { value: 0.4 },
            atmPowFactor: { value: 4 },
            atmMultiplier: { value: 9.0 },
          }}
          blending={THREE.AdditiveBlending}
          side={THREE.BackSide}
        />
      </mesh>
    </>
  );
};

export default Atmosphere;
