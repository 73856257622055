import * as React from "react";
import { useEffect, useState } from "react";
import * as THREE from "three";
import { useFrame } from "@react-three/fiber";
import { getCurrentDate, getUpdatedSatellitePosition } from "../../utils/utils";
import { SceneOptionsContext } from "../SceneOptions/SceneOptions";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import { MAX_SCALE } from "../../utils/constants";
var satellite = require("satellite.js");

interface SatelliteGroupProps {
    divId: string;
    tles: string[][];
    gltf: GLTF;
}

const SatelliteGroup: React.FC<SatelliteGroupProps> = ({ divId, tles, gltf }) => {
    const sceneOptions = React.useContext(SceneOptionsContext);
    const meshRef = React.useRef<THREE.InstancedMesh>(null!);
    const deltaRef = React.useRef<number>(0);
    const [satRecords, setSatRecords] = useState<any[]>([]);
    const [satNames, setSatNames] = useState<string[]>([]);
    const [geometry, setGeometry] = useState<THREE.BufferGeometry | null>(null);
    const initTime = React.useRef<Date | null>(null);
    const animatingRef = React.useRef<boolean>(true);
    const animationMs = 2000;

    useEffect(() => {
        const object = gltf.scene.children[0] as THREE.Mesh;
        const geometry = object.geometry as THREE.BufferGeometry;
        geometry.scale(0.001, 0.001, 0.001);
        setGeometry(geometry);
    }, [gltf]);

    useEffect(() => {
        let newSatRecords: any[] = [];
        let satNames: string[] = [];
        tles.forEach((tle) => {
            satNames.push(tle[0]);
            newSatRecords.push(satellite.twoline2satrec(tle[1], tle[2]));
        });
        setSatNames(satNames);
        setSatRecords(newSatRecords);
        if (initTime.current === null && tles.length > 0) {
            initTime.current = getCurrentDate();
        }
    }, [tles, divId]);

    // add a div for the tooltip if not already present
    useEffect(() => {
        if (document.getElementById("satellite-tooltip") === null) {
            const tooltip = document.createElement("div");
            tooltip.id = divId;
            tooltip.className = "info-tooltip";
            // Add to id canvas-container
            document.getElementById("canvas-container")?.appendChild(tooltip);
        }
    }, [divId]);

    // 30 fps
    const interval = 1 / 30;
    let lastElapsed = 0;
    useFrame((state) => {
        deltaRef.current += state.clock.elapsedTime - lastElapsed;
        lastElapsed = state.clock.elapsedTime;
        if (deltaRef.current < interval) {
            return;
        }
        deltaRef.current = deltaRef.current % interval;
        
        const dummy = new THREE.Object3D();
        const maxScaleDistance = 0.3;
        const maxScale = MAX_SCALE
        for (let i = 0; i < satRecords.length; i++) {
            let scale = sceneOptions.easyViewScale;
            const pv = getUpdatedSatellitePosition(satRecords[i]);
            const position = pv[0];
            const velocity = pv[1];
            if (animatingRef.current) {
                if (initTime.current) {
                    let elapsedMs = getCurrentDate().getTime() - initTime.current.getTime();
                    scale = (sceneOptions.easyViewScale - 1) * (elapsedMs / animationMs) + 1;
                    animatingRef.current = elapsedMs < animationMs;
                }
            } else if (!sceneOptions.easyViewMode) {
                scale = sceneOptions.satelliteScale;
                const satelliteCamCoords = position.clone().project(state.camera);
                const satellite2D = new THREE.Vector2(satelliteCamCoords.x, satelliteCamCoords.y);
                const distance = satellite2D.distanceTo(state.pointer);
                if (distance <= maxScaleDistance) {
                    // Map the distance to the range [1, 1000]
                    scale = maxScale - (distance / maxScaleDistance) * (maxScale - sceneOptions.satelliteScale);
                }
            } else {
                scale = sceneOptions.easyViewScale;
            }
            // Dont render if visible is false. Yes the name is backwards.
            if (sceneOptions.visible[divId] === false) {
                scale = 0;
            }
            dummy.scale.set(scale, scale, scale);
            // rotate along the z axis to match the velocity vector
            dummy.position.set(0, 0, 0);
            dummy.updateMatrix();

            // Set the position and point the z-axis towards the origin.
            dummy.position.set(position.x, position.y, position.z);
            dummy.lookAt(0, 0, 0);

            // Get the current x-axis of the dummy object after lookAt transformation
            const currentXAxis = new THREE.Vector3(1, 0, 0);
            currentXAxis.applyQuaternion(dummy.quaternion);

            // Get the normalized velocity vector
            const velocityNormalized = new THREE.Vector3(velocity.x, velocity.y, velocity.z).normalize();

            // Calculate the quaternion to rotate the current x-axis to align with the velocity vector
            const quaternionXAlign = new THREE.Quaternion().setFromUnitVectors(currentXAxis, velocityNormalized);

            // Apply this quaternion to the dummy object
            dummy.quaternion.multiplyQuaternions(quaternionXAlign, dummy.quaternion);

            dummy.updateMatrix();

            meshRef.current.setMatrixAt(i, dummy.matrix);
        }
        meshRef.current.computeBoundingSphere(); //Necessary for raycasting to work

        state.raycaster.setFromCamera(state.pointer, state.camera);
        const intersects = state.raycaster.intersectObject(meshRef.current);
        const tooltip = document.getElementById(divId);
        if (tooltip !== null) {
            if (intersects.length > 0 && state.pointer.x !== 0 && state.pointer.y !== 0) {
                const idx = intersects[0].instanceId as number;
                const name = satNames[idx].startsWith("ISS") ? "ISS" : satNames[idx];
                const matrix = new THREE.Matrix4();
                meshRef.current.getMatrixAt(idx, matrix);
                const position = new THREE.Vector3();
                position.setFromMatrixPosition(matrix);
                const altitude = position.length() - 6378;
                position.project(state.camera);
                const x = (position.x * 0.5 + 0.5) * window.innerWidth;
                const y = (-position.y * 0.5 + 0.5) * window.innerHeight;

                tooltip.style.left = `${x}px`;
                tooltip.style.top = `${y}px`;
                tooltip.style.display = "block";
                tooltip.innerHTML = name + "<br/>" + altitude.toFixed(2) + " km";
            } else {
                tooltip.style.display = "none";
            }
        }
        meshRef.current.instanceMatrix.needsUpdate = true;
    });

    return (
      <instancedMesh ref={meshRef} args={[null as any, null as any, tles.length]} receiveShadow>
        <bufferGeometry attach="geometry" {...geometry} />
        {sceneOptions.easyViewMode ? (
          <meshBasicMaterial />
        ) : (
          <meshPhongMaterial />
        )}
      </instancedMesh>
    );
};
export default SatelliteGroup;
